import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { useNavigation } from "@remix-run/react";
import { MouseEventHandler, useEffect, useState } from "react";
import { classNames } from "~/lib/util";
import { useRdtState } from "~/rdt/hooks/useRdtState";
import { useRadix } from "~/rdt/radixProvider";

type Props = {
	title: string,
	onClick: MouseEventHandler
}

export default function TransactionButton({ title, onClick }: Props) {
	const { transactionState } = useRadix();
	const walletData = useRdtState();
	const navigation = useNavigation();
	
	const [walletConnected, setWalletConnected] = useState(false);

	useEffect(() => {
		if (walletData?.accounts.length) {
			setWalletConnected(true);
		} else {
			setWalletConnected(false);
		}
	}, [walletData]);

	function processClick(evt) {
		if (!walletConnected) {
			return;
		}

      if (navigation.state == 'submitting') {
         return;
      }

      if (transactionState == 'submitting') {
         return;
      }

      return onClick(evt);
   }

	return (
		<button
			type="button"
			onClick={processClick}
			className={classNames(
				!walletConnected ? 'bg-black/70 cursor-not-allowed' : '',
				navigation.state !== "submitting" && transactionState !== "submitting" ? "focus:ring-2 focus:ring-astro focus:ring-offset-2" : "",
				"tracking-astro font-optical-24-550 flex w-full items-center justify-center rounded-full bg-black p-4 text-xl text-white",
			)}
		>
			{navigation.state == "submitting" ? (
				<>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2 h-4 w-4 animate-spin">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
						/>
					</svg>
					Recalculating
				</>
			) : transactionState == "submitting" ? (
				<>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="animate-bounce-x mr-3 h-6 w-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
					Sign in the Radix Wallet
				</>
			) : !walletConnected ? (
         <>
           <svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mt-1 mr-2 h-7 w-7">
             <path
               d="M170.912 306.613C166.762 306.613 162.817 304.63 160.343 301.216L108.688 229.63H75V203.567H115.352C119.542 203.567 123.467 205.57 125.92 208.964L168.132 267.447L232.543 120.309C234.628 115.566 239.309 112.5 244.481 112.5H325V138.563H253.005L182.85 298.804C180.969 303.097 176.942 306.04 172.281 306.531C171.852 306.592 171.382 306.613 170.912 306.613Z"
               fill="currentColor"
             />
           </svg>
           Connect Wallet
         </>
       ) : (
				<>
					{title} <ChevronRightIcon className="h-6 w-6" />
				</>
			)}
		</button>
	);
}